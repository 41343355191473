import React, { Component } from "react";
import Helmet from "react-helmet";
import config from "../../data/SiteConfig";
import DonateSection from "../sections/DonateSection";
import Footer from "../sections/Footer";

class Donations extends Component {
  render() {
    return (
      <div className="about-container">
        <Helmet title={`Donate | ${config.siteTitle}`} />
        <DonateSection />
        <Footer />
      </div>
    );
  }
}

export default Donations;
