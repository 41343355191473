import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import Header from '../components/Header';
import Image from '../components/Image';
import Icon from '../components/Icon';
// import ThreeKUse from '../components/ThreeKUse';

import PayPal from '../../static/logos/paypal.svg';
import Cheque from '../../static/logos/cheque.svg';
import Logo from '../../static/logos/IconLogo.svg';

const Container = styled.div`
	text-align: center;
	padding: 5vh 5vw 0px 5vw;
	margin: 0 auto 10vh auto;

	@media only screen and (min-width: 768px) {
		padding: 5vh 20vw 0px 20vw;
	}
`;

const TinyText = styled.p`
	font-size: 0.7em;
`;

const BlankSpace = styled.div`
	height: 5vh;
`;

const ContributeCols = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
`;

const PayIcon = styled.img`
	margin: 10px 0px 5px 0px;
	width: 55px;
`;

const FormLink = styled.a`
	width: 150px;
	color: #38c938;
	border-radius: 10px;
	border: solid #38c938 0.5px;
	padding: 10px;
	font-size: 1em;
	background-color: transparent;
	transition: 0.5s ease-in-out;
	outline: none;
	margin: 20px auto;
	display: block;

	:focus {
		outline: 0;
	}

	:hover {
		color: #fff;
		background-color: #38c938;
	}
`;

const DonateSection = () => {
	return (
		<Container>
			<Link to="/">
				<Icon src={Logo} />
			</Link>
			<Header h3>Ways to Donate</Header>
			<p>Thanks for contributing to Glucose Trail.</p>

			<div>
				<a href="https://paypal.me/glucosetrail?locale.x=en_US" target="blank">
					<PayIcon src={PayPal} />
				</a>
				<p>
					Pay online through PayPal, a verified and secure payment processing
					service.
				</p>
				<FormLink
					href="https://paypal.me/glucosetrail?locale.x=en_US"
					target="blank"
				>
					Donate Here
				</FormLink>
				{/* <FormLink href="https://paypal.me/glucosetrail?locale.x=en_US" target="blank">PayPal Donation</FormLink> */}
			</div>
			<p>
				<b>Or</b>
			</p>
			<div>
				<p>
					Mail us a cheque:
					<br />
					<PayIcon src={Cheque} />
					<br />
					Glucose Trail Inc. <br />
					2 Powder Mill Square Unit 4 <br />
					Andover, MA 01810 <br />
					EIN: 83-1318320
				</p>
			</div>

			<BlankSpace />

			<TinyText>
				Glucose Trail Inc is a Public Non-Profit under IRS Section 501(c)(3).
			</TinyText>
			<BlankSpace />
			<Header h3>Become a Partner Organization</Header>
			<p>
				Join the effort of improving health worldwide. If you are in the
				business of providing products or services that help people have better
				nutrition, or encourages physical activity, we welcome your help -
				reaching thousands of patients that need you.
			</p>
			<Header h4>Email us at: info@glucosetrail.com</Header>
		</Container>
	);
};

export default DonateSection;
